body, html {
  max-width: 480px; /* Typical mobile screen width */
  margin: 0 auto; /* Center the content on larger screens */
  background: url('/public/background.png') no-repeat center center; 
  background-size: cover;
  background-color: #061822;
  min-height: 100vh; /* Ensure the background covers the full height */
  overflow: auto; /* Prevents scrolling that might cause shifting */
}

.App {
  padding-top: 0rem; /* Adjust this value as necessary */
  display: flex;
  justify-content: center;
  height: 100%; /* Full height of the container */
  min-height: 100vh; /* Ensure it covers the full viewport height */
  font-size: 24px;
  color: #fff;
}

.first_4 {
  padding-top: 20rem;
  color: #38CAF7;
}

.message {
  color: #38CAF7;
  font-size: large;
}


/* Adjustments for smaller displays and mobile optimization */
@media (max-width: 480px) {
  body, html {
    background: url('/public/background.png') no-repeat center center; 
    background-size: cover; /* Ensure the image covers the entire background */
    min-height: 100vh; /* Ensure the background covers the full height */
  }
  
}

/* Example media query for very small devices */
@media (max-width: 320px) {
  h1, h2, h3, h4, h5, h6 {
    font-size: 1em; /* Adjust as needed */
  }
  .container {
    padding: 10px;
  }
  
}

/* Primary button style */
div.container.mt-5 button.btn-primary {
  background: linear-gradient(to right, #275466, #567B8A); /* Gradient from left (#275466) to right (#567B8A) */
  color: #ffffff;
  border-color: #5C808F;
}

/* Apply focus styles to the primary button */
div.container.mt-5 button.btn-primary:focus {
  border-color: #5C808F;
  box-shadow: 0 0 0 0.2rem #5C808F;
}

div.container.mt-5 button.btn-secondary {
  background: linear-gradient(to right, #63686a, #939090); /* Gradient from left (#275466) to right (#567B8A) */
  color: #ffffff;
}

div.container.mt-5 button.btn-secondary:focus {
  border-color: #5C808F;
  box-shadow: 0 0 0 0.2rem #63686a;
}

/* Styling for radio questions */
div.container.mt-5 .radio-outline-primary {
  color: #38CAF7;
  border-color: #5C808F;
  background-color: transparent;
}

div.container.mt-5 .radio-outline-primary:hover,
div.container.mt-5 .radio-outline-primary:focus {
  color: #fff;
  background-color: #5C808F;
  border-color: #5C808F;
}

div.container.mt-5 .btn-check:checked + .radio-outline-primary,
div.container.mt-5 .btn-check:active + .radio-outline-primary {
  background-color: #5C808F;
  border-color: #5C808F;
  color: #fff;
}

div.container.mt-5 .radio-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem #38CAF7;
}

/* Style of the Slider */
div.container.mt-5 .form-range::-webkit-slider-thumb {
  background-color: #38CAF7;
}

