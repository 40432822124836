.quotes h2 {
  opacity: 0.7;
  transition: opacity 0.5s, transform 0.5s;
}

.quotes .quote-active {
  opacity: 1;
  transform: scale(1.1);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Add a glowing text shadow */
}

.sound-player h2 {
  color: #38CAF7;
  margin: 90px 0;
  text-align: center;
}

.sound-player h3 {
  color: #5C808F;
  margin: 90px 0;
  text-align: center;
  font-size: xx-large;
}

.sound-player h4 {
  color: #5C808F;
}

.sound-player h2:first-of-type {
  margin-top: 40px;
}

.now-playing h2 {
  margin: 0;
}

.play-pause-rectangle {
  border: 5px solid #38CAF7; /* Set the border with width, style, and color */
  border-radius: 20px; /* Adjust the border radius as needed */
  cursor: pointer;
  max-width: 500px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the rectangle */
}

